.stripes {
    top: 0;
    grid: repeat(5,100px)/repeat(1,1fr);
    -webkit-transform-origin: 0;
    transform-origin: 0;
    background: -webkit-gradient(linear,left top,left bottom,from(20%,#005571),color-stop(10%,#005571),color-stop(38%,#dde9f5),color-stop(48%,#eaf2f9),color-stop(62%,#f6f9fc));
    background: linear-gradient(#005571 10%,#005571 10%,#dde9f5 38%,#eaf2f9 48%,#f6f9fc 62%);
    
    position: absolute;
    /* z-index: -1; */
    width: 100%;
    height: 100%;
    display: grid;
    -webkit-transform: skewY(-12deg);
    transform: skewY(-12deg);
   } 
.stripes :first-child {
    grid-column: span 3;
    background: -webkit-gradient(linear,left top,right top,from(rgba(243,251,255,.4)),color-stop(20%,rgba(243,251,255,.15)),to(rgba(243,251,255,0)));
    background: linear-gradient(100grad,rgba(243,251,255,.4),rgba(243,251,255,.15) 20%,rgba(243,251,255,0));
}

.stripes :nth-child(2) {
  background: -webkit-gradient(linear,left top,right top,from(rgba(0, 204, 177, 0)),color-stop(40%,rgba(0,119,204,.05)),to(rgba(0,119,204,.35)));
  background: linear-gradient(100grad,rgba(0,119,204,0),rgba(0,119,204,.05) 40%,rgba(2, 89, 95, 0.35));
  grid-area: 3/span 3/auto/-1;
}
.stripes :nth-child(3) {
  background: -webkit-gradient(linear,left top,right top,color-stop(50%,#fff),to(hsla(0,0%,100%,0)));
  background: linear-gradient(100grad,#fff 50%,hsla(0,0%,100%,0));
  grid-row: 4;
    grid-column: span 5;
}