.register-form{
    width: 95%;
}

.selected-itens.form-control{
    font-size:larger !important;
    height: 56px !important;
}

.default-select{
    color: blueviolet !important;
}

.register-input{
    padding: 5px 0px 5px 25px;
    flex: 1;
    height: 56px;
    color: #333;
    font-size: 15px;
    min-width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 0 !important;
    margin-top: 16px !important;
}

.recaptcha {
    margin-top: 16px !important;
}

.login-button{
    margin-top: 16px !important;
}

.red{
    color: #e32636 !important;
}