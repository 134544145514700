/** error */
.error{
  color: #ff0000 !important;
}
/* lista */
.list{
  padding: 0;
  background-color: #ffffff;
  width: 200px;
  overflow: auto;
  height: 100%;
  position: relative;
  display: flexbox;

}
.alert-container {
  padding: 20;
}
.list ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* button add */
.alert-add-button{
  margin-left: 10px;
  margin-right: 10px;
  font-size: small;
  width: 180px;    
  margin-top: 7px;
  margin-bottom: 10px;
}

/* itens */
.alert-item{
  color: #343a40;
  margin-left: 10px;
  margin-right: 10px;
  width: 180px;    
  margin-top: 7px;
}

/* alert-editor */
.alert-editor{
  background-color: #ffffff;
  padding: 0;
  overflow: auto;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 60px auto;

}
.title-alert-editor{
  padding-left: 10px;
  padding-top: 10px;
  width: 100%;

}


.grid-div{
  display: grid;
  grid-template-columns: 200px auto;
  height: 100%;
  width: 100%;
}



/* CSS para o novo modelo de alerta*/
.textList {
  color: #666;
  font: 1.2em sans-serif;
  font-weight: 600;
  padding: 20;
}

.listScroll {
  height: 18rem;
  overflow-y: scroll;
}
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
text-decoration: none;
color: #0048CD;
}

/*CSS da tabela*/
#minhaTabela{
  height:auto;
  width:100%;
  margin:0 auto;
  border:0;
  /* box-shadow: 0 5px 30px darkgrey; */
  border-spacing: 0;
}

#minhaTabela thead th{
  font-weight: bold;
  background-color: var(--light);
  color:var(--colorText);
  padding:8px 10px;
  font-size:12px;
  vertical-align:middle;
}

#minhaTabela tr td{
  vertical-align:middle;
  font-size:13px;
  padding:8px 10px;
  text-align: left;
  /* cursor: pointer; *importante para não mostrar cursor de texto* */
}

#minhaTabela tr td:last-child{
  text-align: right;
}

/**Cores**/
#minhaTabela tr:nth-child(odd){
  background-color: #fff;
}

/**Cor quando passar por cima**/
#minhaTabela tr:hover td{
  background-color: var(--light);
}

.no-hover:hover td{
  background-color: #eee !important;
}
.no-hover td{
  background-color: #eee !important;
}
.no-hover{
  background-color: #eee !important;
}

/**Cor quando selecionado**/
#minhaTabela tr.selecionado td{
  background-color: #aff7ff;
}


button#visualizarDados{
  background-color: white;
  border: 1px solid black;
  width:50%;
  margin: 10px auto;
  padding:10px 0;
  display: block;
  color: black;
}

.col-md-12{
  padding: 0 !important;
}

.form-control {
  font-size: .8rem !important;
}