.toolt {
    position: relative;
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    margin-left: 1em;
    margin-right: -1em;
    font-weight: 500;
    line-height: 1;
    width: 20px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border: 1px solid var(--colorGray6);
    border-radius: .6rem;
    padding: 3px;
}

.toolt .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px 0;
    margin-left: 2px;
    position: relative;
    z-index: 1;
    /* top: -20px; */
    left: 100%;
  }
.toolt b {
    margin-left: 3px;
  }

  .toolt .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
  .toolt:hover .tooltiptext {
    visibility: visible;
  }

  
@media (max-width: 768px) {
    .toolt {
        white-space: normal;
      }
      
      .toolt .tooltiptext {
        width: 240px;
        position: absolute;
        top: 150%;
        left: 50%;
        margin-left: -125px;
      }
      .toolt b {
        margin-left: 1px;
      }
      .toolt .tooltiptext::after {
        bottom: 100%;
        left: 50%;
        margin-left: 0px;
        top: unset;
        right: unset;
        margin-top: unset;
        border-color: transparent transparent black transparent;
      }
      
      
    }