
/* containers */
.results-container {
  width: 100%;
}

.result-list {
  margin-top: 0px;
  margin-bottom: 10;
  min-height: 100vh;
}
 
.result-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
 
.result-list-info {
  margin-bottom: 1rem;
  justify-content: space-between;
  font-size: small;
  color: #666;
}

.filter-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: small;
  color: #666;
}


.result-list-pagination {
  margin: 40px 0 50px;
  font-size: small;
}

.result-list-pagination .div .a:active {
  background-color: #005571;
}

.results-container .powered-by {
  display: none;
}

.noResults {
  color: #666;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: va(--colorGray9);
}

  .search-input {
    min-width: 200px;
    border-radius: 30px;
    padding: 5px 16px 5px 16px;
    background: #fafafa;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    height: 50px;
  }
  
  .search-input:focus {
    border-color: #007bff;
    box-shadow: 0 1px 0 0 #007bff ;
  }
  
  .css-1qtpd4b-InputGroup {
    border-radius: 30px !important;
  }
  /* flex layouts */
.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.wrap {
  flex-wrap: wrap;
}

.m10 {
  margin: 10px;
}

.m10-0 {
  margin: 10px 0;
}

.m1,
.result-list .m1 {
  margin: 1rem;
}

@media screen and (max-width: 600px) {
  .filter-info {
    padding-left: 40;
  }
}