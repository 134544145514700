.component-relative{
  position: relative;
  left: 200px;
}


/* folders component */
.background{
  position: fixed;
  left: 200px;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  padding: 0;
  z-index: 1031;
}
.sidebar-hidden{
  left: 0 !important;
}
.sidebar-hidden{
  left: 200 !important;
}
/* breadcrumb-style */
.breadcrumb-conteiner{
  background-color: var(--colorF1) !important;
  height: 40px;
  min-height: 40px;
}

.icon-sidebar{
  width: 24px !important;
  flex: 0 0 7px !important;
}

.breadcrumb-component{
  display: block;
  margin-left: 20px !important;
  padding-left: 0px !important;
}
.breadcrumb{
  display:flex;
  margin: 0;
  padding: 0;
  background-color: transparent;
  width: auto;
}

/* breadcrumb-style */



/* sidebar */

.folder-sidebar{
  position: fixed;
  border-top-right-radius: 3px;
  border: thin solid var(--bg-colorTheme);
  top: auto;
  z-index: 1030;
  width: 200px;
  height:100%;
  max-height:100%;
}
.folder-sidebar{
  text-align: center;
}

.folder-sidebar ul{
  list-style: none;
  margin:0;
  padding:0;
  padding-top: 5px;
  padding-bottom: 5px;
 
}

.button-new{
  /*position*/
  border-radius: 30px 30px 30px 30px;
  margin:15px;
  margin-left:20%;
  margin-right:20%;
  width: auto;
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding-left:12%;
  /* style */
  color: var(--colorText) !important;
}

.button-new:hover{
  border-color: var(--colorGray9) !important;
  background-color: var(--colorGray9) !important;
  color: var(--colorHover3) !important;
}

.sidebar-item a{
  color: var(--colorText) !important;
}

.sidebar-item a:link{
  text-decoration: none;
  
}
.sidebar-item a:hover{
  color: var(--colorHover3) !important;
}

/* grid da estrutura de pastas */

.structure-folders {
  background-color: var(--white) !important;
  margin-top: 40px;
  margin-left: 15px !important;
  height: 100%;
}
.header-row{
  position: fixed;
  background-color: var(--white);
  overflow: hidden;
  width: 100%;
  z-index: 500;
  padding-top: 3px;
  padding-bottom: 3px;
}
.item-row{
  position: relative;
  top: 25px;
  padding: 5px;
}
.col{
  display: flex;
  align-items: center;
  
}

.col-name{
  width: 200px !important;
  max-width: 200px !important;
}
.col-type{
  width: 150px !important;
  max-width: 150px !important;
}

.hr-horizontal {
  border-bottom: thin solid var(--colorGray9);
}

.scrollable{
  overflow: scroll;
}

.header-folder{
  margin: 5px;
  background: tomato;
  text-align: center;
  font-size: 1.5em;
}

.item-folder {
  margin: 5px;
  background: blue;
  text-align: center;
  font-size: 1.5em;
}

.left-space{
  margin-left: 10px;
}

/* sidebar menu responsive */
@media (max-width:800px){
  .background{
    left: 0 !important;
  }
  .icon-sidebar{
    visibility: hidden;
  }

  .breadcrumb-component{
    display: block;
    margin-left: 40px !important;
    padding-left: 0px !important;
  }

}
/* end sidebar menu responsive */


/* modals */
.modals-form{
  min-width: 90% !important;
  max-width: 99% !important;
  width: auto !important ;  
  margin-right: auto !important;
}

/* end modals */