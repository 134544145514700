
 .singleDropdownList ul li label {
	font-size: 12px;
 }
 .singleList ul li label {
	font-size: 12px;
   font-weight: normal !important;
 }

 .dateInput {
	/* height: 40px;  */
    padding: 5px 0px;
    font-size: 0.6rem;
 }

 .DayPickerInput input {
    width: 100%;
    height: 32px !important;
    padding: 0px 0px 0px 5px !important;
    /* border: 1px solid #999 !important; */
    /* background-color: #fafafa !important; */
    font-size: 0.8rem !important;
    outline: none;
    background-color: #fff;
    color: #424242;
    border: none;
}

.showFilter {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   background-color: #fff;
}

.showFilter-block {
   max-width: 25%;
   align-items: center;
   width: max-content;
   /* padding: 10px; */
   margin-bottom: 20px;
   color: var(--colorGray3);
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   flex: 25%;
    
}

.accordion>.card {
   overflow: inherit !important;
}

.card-body {
   padding: .25rem !important;
}

.showFilter >div:not(:last-child) {
   border-right-width: 0!important;
}
.css-1kls8xb-Flex-leftLabel-Flex {
   border: 1px solid #C4C4C4 !important;
}
.label {
   font-weight: normal !important;
}

@media (max-width: 768px) {
   .showFilter-block {
   max-width: none;
   flex: 50%;
   }
 }
@media (max-width: 576px) {
   .showFilter-block {
     flex: 100%;
   }
 }