
.link-pdf {
    color: var(--colorLink);
    font-size: x-large;
    margin-top: 10;
    background-color: transparent;
}
.link-pdf:hover {
    font-weight: bold;
}

.pdf-viewer {
    width: 100%;
    height: 97.5%;
}

@media (max-height: 2000px) {  
   .pdf-viewer{
       height: 95%;
   }
}

@media (max-height: 1300px) {  
    .pdf-viewer{
        height: 92.5%;
    }
}

@media (max-height: 825px) {  
    .pdf-viewer{
        height: 90%;
    }
}

@media (max-height: 625px) {  
    .pdf-viewer{
        height: 85%;
    }
}

@media (max-height: 425px) {  
    .pdf-viewer{
        height: 80%;
    }
}

@media (max-height: 300px) {  
    .pdf-viewer{
        height: 75%;
    }
}