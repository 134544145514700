.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 0.8rem !important;
    line-height: 1.5;
    border-radius: .25rem;
    transition: all .30s;
}
.close-button{
    margin-right: 0;
}
.save-button{
    margin-right: 0;
}

.void-col{
    margin-right: auto;
}
.save-btn{
   color:#fff !important;
   border-color: var(--bg-colorTheme) !important;
   background-color:var(--bg-colorTheme) !important;
}

.save-btn:hover{
    color: var(--bg-colorTheme) !important;
    border-color: var(--bg-colorTheme) !important;
    background-color: #fff !important;
    
}

.close-btn{
    color:#fff !important;
    border-color: var(--colorGray9) !important;
    background-color:var(--colorGray9) !important;
}
.close-btn:hover{
    color: var(--bg-colorGray6) !important;
    border-color: var(--bg-colorGray6) !important;
    background-color: #fff !important;

}

.checked-advanced{
    display: inline-block;
    padding-left: 20;
    margin-bottom: .5rem;
    margin-left: 0;
    margin-right: auto;
}

.padding-redux {
    padding-top: 0;
}