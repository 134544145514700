
   
.div-container {
  margin-top: 100;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
  }
  .div-bar {
    width: 400;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    justify-content: center;
    margin-right: auto;
    align-items: center;
    border-top: 10px solid #999;
    border-bottom: 10px solid #999;
    color: #666;   
  }
  
  
