



/* components */
.avatar {
  height: 50px;
  width: 50px;
	border-radius: 50%;
}

.origin-list {
  color: var(--colorGray9);
	font-weight: bold;
}
.title-list {
  color: var(--colorGray3);
	font-weight: bold;
}

a.link:hover {
  display: block;
  text-decoration: none;
  color: #fff;
}

.link {
  /* background-color: pointer; */
	color: #fff;
	text-decoration: none;
}

.title {
  font-size: 14px;
    color: #333;
    margin-top: 0px;
  }
  .subtitle {
    white-space: normal;
    /* margin-top: 4px; */
  }
  .lineHeight {
  line-height: 20px;
}
.descrition {
  font-size: 12px;
  color: #666;
  margin-top: 0px;
  padding-left: 20px;
}

.result-item { 
  background-color: #fff;
  margin: 0 15px;
  padding: 25px 25px 25px 100px;
      border: 1px solid #eee;
      flex-basis: 100%;
      max-width: 100%;
      min-height: 100px;
      /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
      /* font-size: 1rem; */
      display: flex;
      flex-direction: row !important;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 5px;
  }
.item-header {
  flex-wrap: wrap;
  gap: 12px;
  }
  
  .result-item:hover {
    background-color: #fafafa;
  }
  
  /* flex layouts */
.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.wrap {
  flex-wrap: wrap;
}

.m10 {
  margin: 10px;
}

.m10-0 {
  margin: 10px 0;
}

.m1,
.result-list .m1 {
  margin: 1rem;
}

@media screen and (max-width: 600px) {
  
.result-item { 
  padding: 5px;
  }
}