.menu {
  margin-bottom: 0px;
  background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    min-height: 52px;
}

.shadow{
  box-shadow: none;
  box-shadow: 0 5px 5px 0 rgba(0,0,0,0.19);
}
.toggle-button {
    cursor: pointer;
    color: var(--colorText);
    font-size: small;
    border: 1px solid var(--colorText);
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
    text-align: center;
    max-width: 150px;
    align-self: left;
    display: inline-table;
    top: 10px;
    
    }
.toggle-button-red {
  color: red;
  border: 1px solid red;
}

    
    .toggle-button:hover {
        color: #fff;
        background-color:  var(--colorText);
      }
    .toggle-button-red:hover {
        color: #fff;
        background-color:  red;
      }
      
      .menu ul {
        width: 50%;
        min-height: 50px;
        padding: 1.2em 1em;
        margin:0px;
        background-color:#fff;
        list-style:none;
        border-bottom:0px solid #EDEDED;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
      
      .menu ul li { 
        /* display: inline-block;  */
        line-height: normal;
        display: block;
        float: right;
        margin-right: 10px;
    }
    
    .menu ul li a {
      padding: 2px 10px;
      display: inline-block;
    
      /* visual do link */
      color: var(--colorText);
      text-decoration: none;
      
    }
     
    .menu ul li a:hover {
      color: var(--colorText);
      border-bottom:3px solid var(--colorText);
    }

     /* Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) { 
  .menu {
    display: flex;
    flex-direction: column;
  }
  
  .menu ul li { 
    min-width: -webkit-fill-available;
    
}
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 0rem !important;
}