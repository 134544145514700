
.footer {
    border-radius: 0px;
    padding: 0 20px;
    color: var(--colorText);
    background-color: var(--colorF1);  
    border-top: 3px solid var(--colorText);
    height: 80px;
    width: 100%;
    position: relative;
    bottom: 0px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
  .footer-theme {
    color: var(--colorText) !important;
    /* font-weight: 700; */
    background-color: var(--colorActive);
    border-left: 5px solid var(--colorText);
  }
  
  @media screen and (max-width: 576px) {
    .footer {
      position: fixed;
      padding-right: 0px;
      text-align: center;
    
    }
  }