
/* Flex */
.flex {
	display: flex;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-item-1 {
	flex: 1;
}

/* Flex Item */
.item {
    margin: 15px;
    padding: 2rem;
    background: var(--light);
    border-left: 3px solid var(--colorText);
	text-align: center;
    font-size: 1.5em;
    color: var(--colorText);
    text-decoration: none;
}
.item:hover {
    color: var(--colorText);
    font-weight: bold;
    text-decoration: none;
}

.container-admin {
    /* display: grid; */
	width: 100%;
    position: relative;
    padding: 2rem;
    background-color: white;
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    border-radius: .25rem;
}
