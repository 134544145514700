/* -------------------------------- 

Basic Style

-------------------------------- */
.cd-breadcrumb, .cd-multi-steps {
    width: 50%;
    /* max-width: 768px; */
    padding: 0.5em 1em;
    margin-right: 2em auto;
    background-color:transparent;
    /* border-radius: .25em; */
    position: relative;
  }
  .cd-breadcrumb::after, .cd-multi-steps::after {
    clear: both;
    content: "";
    display: table;
  }
  .cd-breadcrumb li, .cd-multi-steps li {
    display: inline-block;
    /* float: left; */
    margin: 0.5em 0;
  }
  .cd-breadcrumb li::after, .cd-multi-steps li::after {
    /* this is the separator between items */
    display: inline-block;
    content: '\00bb';
    margin: 0 .6em;
    color: var(--colorGray6);
  }
  .cd-breadcrumb li:last-of-type::after, .cd-multi-steps li:last-of-type::after {
    /* hide separator after the last item */
    display: none;
  }
  .cd-breadcrumb li.visited > *, .cd-multi-steps li > * {
    /* single step */
    display: inline-block;
    font-size: 1rem;
    color: var(--colorGray9);
  }
  .cd-breadcrumb li.current > *, .cd-multi-steps li.current > * {
    /* selected step */
    color: var(--colorGray3);
    font-size: 1rem;
    font-weight: bold;
  }
  .no-touch .cd-breadcrumb a:hover, .no-touch .cd-multi-steps a:hover {
    /* steps already visited */
    color: var(--colorHover);
  }
  .cd-breadcrumb.custom-separator li::after, .cd-multi-steps.custom-separator li::after {
    /* replace the default separator with a custom icon */
    content: '';
    height: 16px;
    width: 16px;
    background: url(https://codyhouse.co/demo/breadcrumbs-multi-steps-indicator/img/cd-custom-separator.svg) no-repeat center center;
    vertical-align: middle;
  }
.cd-breadcrumb.custom-icons li > *::before, .cd-multi-steps.custom-icons li > *::before {
  /* add a custom icon before each item */
  content: '';
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: .4em;
  margin-top: -2px;
  background: url(https://codyhouse.co/demo/breadcrumbs-multi-steps-indicator/img/cd-custom-icons-01.svg) no-repeat 0 0;
  vertical-align: middle;
}
.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(2) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(2) > *::before {
  /* change custom icon using image sprites */
  background-position: -20px 0;
}
.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(3) > *::before {
  background-position: -40px 0;
}
.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(4) > *::before {
  background-position: -60px 0;
}
.cd-breadcrumb.custom-icons li.current:first-of-type > *::before, .cd-multi-steps.custom-icons li.current:first-of-type > *::before {
  /* change custom icon for the current item */
  background-position: 0 -20px;
}
.cd-breadcrumb.custom-icons li.current:nth-of-type(2) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(2) > *::before {
  background-position: -20px -20px;
}
.cd-breadcrumb.custom-icons li.current:nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(3) > *::before {
  background-position: -40px -20px;
}
.cd-breadcrumb.custom-icons li.current:nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(4) > *::before {
  background-position: -60px -20px;
}

ol::before {
  content: " | ";
  font-size: 1rem;
  color: var(--colorGray9);
  }
ol {
  padding-inline-end: 40px;
}