:root {
    /* --colorText: #009688; */
    --colorText: #005571;
    --secondary: #e9ecef;
    --bg-colorTheme:#005571;
    --bg-colorTheme2:#009688;
    --colorLink: #0048CD;
    --colorHover: #eaf5f8;
    --colorHover2: #009688;
    --colorHover3: #16181b;
    --colorActive: #eaf5f8;
    --colorVisited: #990026;
    --colorGray: #6c757d;
    --colorGray3: #333;
    --colorGray6: #666;
    --colorGray9: #999;
    --colorF1: #f1f1f1;
    --colorHover: #d2d2d2;
    --colorRed: red;
    --light: #f8f9fa;
    --orange: #e87c00;
    
}
.wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    min-height: 100vh;
    background-color: var(--colorF1);  
    background-size: cover;

    font-family: "Open Sans", "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* a:link {text-decoration: none;color: var(--colorLink)} */
/* a:active {text-decoration: none;color: var(--colorLink)} */
/* a:hover {text-decoration: none;color: var(--colorHover)} */
/* a:visited {text-decoration: none;color:var(--colorVisited)} */

.content { 
    border-left: 1px solid #ccc;
    background-color:#fff;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
   }

  
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: auto;
    margin-left: auto;
  }

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
text-decoration: none;
color: var(--colorLink);
}


.global-class-icons {
  font-size: 20;
  color: var(--colorText);
  vertical-align: middle;
  cursor: pointer;
}
.logout-class-icons {
  font-size: 20;
  color: var(--colorGray9);
  vertical-align:middle;
  margin-bottom: 3;
  cursor: pointer;
}

.initial-class-icons {
  font-size: 80;
  color: var(--colorText);
  vertical-align: middle;
}
.details-class-icons {
  font-size: 30;
  color: var(--colorText);
  vertical-align: middle;
}

.title-class-icons {
  font-size: 40;
  color: var(--colorText);
  vertical-align: middle;
  padding-right: 10;
  cursor: pointer;
}
.modal-class-icons {
  margin-top: 10px;
  font-size: 40;
  color: var(--light);
  vertical-align: middle;
  cursor: pointer;
}
.badge-class-icons {
  font-size: 16;
  color:white;
  vertical-align: middle;
  cursor: pointer;
}
.badge:hover {
  cursor: pointer;
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.box-shadow {
  background-color: white;
  box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
  border-radius: .25rem;
}


.col-xs-4 {width: 33.33%;}
.col-xs-8 {width: 66.66%;}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 769px) {
.col-l-1 {width: 8.33%;}
.col-l-2 {width: 16.66%;}
.col-l-3 {width: 25%;}
.col-l-4 {width: 33.33%;}
.col-l-5 {width: 41.66%;}
.col-l-6 {width: 50%;}
.col-l-7 {width: 58.33%;}
.col-l-8 {width: 66.66%;}
.col-l-9 {width: 75%;}
.col-l-10 {width: 83.33%;}
.col-l-11 {width: 91.66%;}
.col-l-12 {width: 100%;}
}
/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {  
.col-m-1 {width: 8.33%;}
.col-m-2 {width: 16.66%;}
.col-m-3 {width: 25%;}
.col-m-4 {width: 33.33%;}
.col-m-5 {width: 41.66%;}
.col-m-6 {width: 50%;}
.col-m-7 {width: 58.33%;}
.col-m-8 {width: 66.66%;}
.col-m-9 {width: 75%;}
.col-m-10 {width: 83.33%;}
.col-m-11 {width: 91.66%;}
.col-m-12 {width: 100%;}
}

/* Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) { 
.col-s-1 {width: 8.33%;}
.col-s-2 {width: 16.66%;}
.col-s-3 {width: 25%;}
.col-s-4 {width: 33.33%;}
.col-s-5 {width: 41.66%;}
.col-s-6 {width: 50%;}
.col-s-7 {width: 58.33%;}
.col-s-8 {width: 66.66%;}
.col-s-9 {width: 75%;}
.col-s-10 {width: 83.33%;}
.col-s-11 {width: 91.66%;}
.col-s-12 {width: 100%;}
}

/* Small devices (400px and down) */
@media (max-width: 400px) { 
  .col {
      width: 100%;
    }
}


