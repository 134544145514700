
  /* .container .nav {
    width: 101%; 
    position: fixed;
    top: 5;
    left: 0;
    height: 52px;
    padding: 10px 25px;
  } */
  .container .left-col {
    width: 320px;
    padding: 15px 20px;
    position: fixed;
    left: 0;
    right: 0;
    border-right: 1px solid #fbfbfb;
  }
  
  .range {
    margin: 45px 0px;
    position: flex;
  }
  
  .container .right-col {
    width: calc(100% - 320px);
    position: relative;
    left: 320px;
    padding: 25px 10px;
    background-color: #fbfbfb;
  }
  .container .right-col .list {
    margin-bottom: 30px;
  }
  .container .right-col .list-item {
    max-width: none;
    min-width: 0;
    width: calc(30% - 16px);
    height: auto;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    position: relative;
    padding: 0;
  }

  .container .right-col .list {
    margin-bottom: 30px;
    display: flex;
  }
  .container .right-col .list-item {
    max-width: none;
    min-width: 0;
    width: calc(30% - 16px);
    height: auto;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    position: relative;
    padding: 0;
  }
  .container .right-col .list-item .image {
    background-size: cover;
  }
  .container .right-col .list-item .price {
    width: 70px;
    height: 44px;
    background-color: #424242;
    position: absolute;
    top: 160px;
    left: 0;
    color: #fafafa;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.03rem;
  }
  .container .right-col .list-item .info {
    color: #555;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .container .right-col .result-stats {
    text-align: right;
    color: #666;
    font-size: 15px;
  }
