.dd-header {
  width: 30%;
  min-width: 200px;
  /* height: 38px;
  display: flex; */
  padding: 5px;
  /* margin: 0 0px; */
  border: 1px solid var(--colorHover);
  border-radius: 3px;
  background-color: #fff;
  min-height: 40px !important;
} 

.dd-header-logout {
  padding-left: 0 !important;
}
.dd-header-title {
    width: 100%;
    margin: 5px;
    margin-right: 20px;
    color: var(--colorGray6);
}
.dd-header-smg {
  width: 100%;
  padding-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  /* gap: 20px; */
}

.dd-list-item {
    border-bottom: 1px solid var(--colorF1);

}
.menu-drop {
  width: 100%;
  /* min-width: 100px; */
  margin: 0px auto 0;
  padding: 20 0 20 0px;
  display: inline-flex;
  flex-wrap: wrap;
  overflow: hidden;
  transition: max-height 1.2s ease-out;
}
  .drop-content{
  display: inline-flex;
  padding: 0px;
  flex-wrap: wrap;
  position: relative;
  background-color: white;
  border-right: 1px solid var(--colorHover);
  /* overflow: auto; */
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
  width: 100%;
  }
  .drop-content ul {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }
  .drop-content li{
    color: var(--colorGray3);
    /* padding: 8px 10px; */
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10;
  }
  .drop-content li:hover{
    background-color: var(--colorActive);
    /* border-left: 3px solid var(--colorActive); */
    cursor: pointer;
  }

  .selected {
    background-color: var(--colorHover2);
  }
  .drop-group {
    align-items: center;
    width: max-content;
    padding: 10px;
    /* margin: 10px; */
    color: var(--colorGray3);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    border: 1px solid var(--colorHover);
  }
  .drop-content>div:not(:last-child) {
    border-right-width: 0!important;
}
.drop-group hr {
  margin-top: 8px;
}
  .drop-row {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  .input-gp {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* -ms-flex-wrap: wrap; */
    /* flex-wrap: wrap; */
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    /* width: 100%; */
}
  .input-group-text {
    width: 100%;
    cursor: pointer;
    margin-left: -1;
    font-size: .8rem !important;
  }

  .input-group-text-logout {
    width: 100%;
    cursor: pointer;
    margin-left: -1;
  }

  .input-group-item {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .input-group-prepend {
    margin-right: -1;
  }
  .input-group-text:hover {
    background-color: #fafafa;
  }
  .selectedColor {
    background-color: #ffeeba;
  }
  /* Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) { 
  .drop-group {
    min-width: -webkit-fill-available;
  }
}
