/* ---------------------------------------------------
    SIDEBAR ICON STYLE
----------------------------------------------------- */

.sidebar-icon{
    color: var(--colorGray6);
    font-size: 0.8rem;
    background-color: var(--secondary) !important;
    border: 1px solid #ced4da;
    border-radius: .25rem !important;
    vertical-align: middle;
}
.sidebar-icon:hover{
    cursor: pointer;
    color: white;
    background-color: var(--colorGray6) !important;
}


