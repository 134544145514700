/* CarouselDemo.css */

.carousel {
 display: flex;
 flex-direction: row;
 width: 60%;
 height: 100%;
 justify-content: center;
}
.carousel .product-item .product-item-content {
    margin: .3rem;
    text-align: center;
    padding: 2rem 0;
    color: #fff;
}

.carousel h4 {
    color: #fff;
}

.img-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/logoDinte.png");
    /* opacity: .8; */
    width: 330px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 250px 250px; /* Resize the background image to cover the entire container */
}
.p-carousel-content {
    overflow: auto;
    justify-content: center !important;
}